import React,{useState,useEffect} from "react";
import Logo from "../../assets/images/svg/Logo.svg";
import { Homestyle } from "./Style";
import jwt_decode from 'jwt-decode';
import repayment from "../../assets/images/repayment.png"
import document from "../../assets/images/document.png"
import masterdata from "../../assets/images/master-data.png"
import institute from "../../assets/images/institute.png"
import emis from "../../assets/images/createmanual.png"
import overdue from "../../assets/images/overdue.png"
// import dropdown from "../../assets/images/dropdown.png";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dropdown from '@mui/icons-material/KeyboardArrowRight';
import dashboardIcon from "../../assets/images/dashboardIcon.png";
import homeIcon from "../../assets/images/home.png";
import userSearch from "../../assets/images/userSearch.png";
import roles from "../../assets/images/roles.png";
import box from "../../assets/images/box.png";
import loans from "../../assets/images/loans.png";
import statusIcon from "../../assets/images/statusIcon.png";
import dropup from "../../assets/images/dropup.png";
import 'bootstrap/dist/css/bootstrap.css';
import OverduetTable from "../Overdue/Overdue";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import "./index.css"
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import TagIcon from '@mui/icons-material/Tag';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import rules from "../../assets/images/rules.jpg"
import BijayLogo from '../../assets/images/bijayfinance_logo.png'
import Demographical  from "../Institute Dashboard/Demographical";

import {
  Box,
  TextField,
  InputAdornment,
  List,
  ListItem,
  Button,
  Typography,
  Card,
} from "@mui/material";


import { useLocation } from "react-router-dom";
import { event } from "jquery";


export default function Sidebar({isOpenSidebar,handler}) {
  const [isOpen, setIsOpen] = useState(() => {
    const savedIsOpen = sessionStorage.getItem('isOpen');
    return savedIsOpen === 'true'; // Convert to boolean
  });
  
  const [isOpen1, setIsOpen1] = useState(() => {
    const savedIsOpen1 = sessionStorage.getItem('isOpen1');
    return savedIsOpen1 === 'true';
  });

  const [isOpenHome, setIsOpenHome] = useState(() => {
    const savedIsOpenHome = sessionStorage.getItem('isOpenHome');
    return savedIsOpenHome === 'true';
  });

  const [isOpenLMS, setIsOpenLMS] = useState(() => {
    const savedIsOpenLMS = sessionStorage.getItem('isOpenLMS');
    return savedIsOpenLMS === 'true';
  });

  const [isOpenAdmin, setIsOpenAdmin] = useState(() => {
    const savedIsOpenAdmin = sessionStorage.getItem('isOpenAdmin');
    return savedIsOpenAdmin === 'true';
  });


  const [dropdownItems, setDropdownItems] = useState([]);
  

  const user = sessionStorage.getItem("user")
  const url =`/admin/application/count`;
  const decode=jwt_decode(user)

  const [isClicked, setIsClicked] = useState(false);
  const toggleDropdown = () => {
    setIsClicked(!isClicked);
    setIsOpen((decode.role === "institute_user" || decode.role === "institute_admin") ? true : !isOpen);
    
  };

  useEffect(() => {
    sessionStorage.setItem('isOpen', isOpen.toString());
  }, [isOpen]);

  useEffect(() => {
    sessionStorage.setItem('isOpen1', isOpen1.toString());
  }, [isOpen1]);

  useEffect(() => {
    sessionStorage.setItem('isOpenHome', isOpenHome.toString());
  }, [isOpenHome]);

  useEffect(() => {
    sessionStorage.setItem('isOpenLMS', isOpenLMS.toString());
  }, [isOpenLMS]);

  useEffect(() => {
    sessionStorage.setItem('isOpenAdmin', isOpenAdmin.toString());
  }, [isOpenAdmin]);




  const [isClick, setIsClick] = useState(false);
  const toggleDropdown1 = () => {
    setIsClick(!isClick);
    setIsOpen1(!isOpen1);  
  };
  
  const toggleDropdownHome = () => {
    setIsOpenHome(!isOpenHome);
    
  };

  const toggleDropdownLMS = () => {
    setIsOpenLMS(!isOpenLMS);
    
  };

  const toggleDropdownAdmin = () => {
    setIsOpenAdmin(!isOpenAdmin);
    
  };

  
  const location = useLocation();
  const navigate = useNavigate()
  
  useEffect(() => {

 
    axiosInstance
      .get(url,{
        headers: {
          Authorization: `Bearer ${user}`,
        }
      })
      .then((response) => {
    
        setDropdownItems(response.data.data)
        
      }
      )
      .catch((error) => console.error(error));
  }, [url,user]);
   
      const handleNavigate = (path)=>{
        sessionStorage.removeItem("page")
        window.location=path
       
      }
   
  
  const filteredDropdownItems = dropdownItems.filter((_, index) => index !== 1 );
  const [sidebarOpen,setSidebarOpen] = useState(isOpenSidebar)
  
  const handlerFunction=()=>{
    handler(!sidebarOpen)
    setSidebarOpen(!sidebarOpen)
  }



  return (
    <>
    {sidebarOpen === true ? (
      <>
       <Homestyle className="Home" style={{width : '20%',boxShadow:'0px 8px 10px rgba(3, 3, 3, 0.28)'}}>
      <ArrowBackIosIcon style={{marginLeft:'95%',cursor:'pointer', color:'#667085'}} onClick={()=>handlerFunction()}/>

        <div className="paper-setting"  style={{marginTop:'-2rem'}}>
          <Box >
            <Link to="/">
              <Box component="img" alt="" src={decode?.clientId==='98ffb18a-9fee-43a5-91a3-4b2b9d9ae361'? BijayLogo:   Logo} sx={{width:'180px'}}/>
            </Link>


           
          </Box>
        <Box my={5} display="flex" justifyContent="center" width="100%">
            {/* <TextField
              className="searchiput"
              id="outlined-basic"
              placeholder="Search"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />{" "}
                  </InputAdornment>
                ),
              }}
            /> */}

          {location.pathname  !=='/dashboard' &&  <Link to="/" className="linkdco" >
            <ListItem
              className={`${
                location.pathname === "/dashboard"
                  ? "list-top"
                  : "list-top-2"
              }`}
            >
              <Button color="primary"   fullWidth={true} size="small" style={{ width: '150px', backgroundColor:'#d32028' }} >
                <List className=" List-main-inner " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                
                  <ListItem className="user-setting" style={{ justifyContent: 'center', width: '100%' }}>
                    <Typography
                      variant="body1"
                      fontFamily="Inter-Medium"
                      gutterBottom
                      style={{fontWeight:'bold',fontFamily:'Outfit', color: 'white'}}
                    >
                      New Lead
                    </Typography>
                  </ListItem>
                </List>
              </Button> 
            </ListItem>
          </Link>
          }
        </Box>   

         
          <List className="list-man-nav">

          <Link to="/usersearch" className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/usersearch"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  {/* inner List started */}
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={userSearch} alt="search" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        User Search
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                {/* inner List ended */}
              </ListItem>
            </Link>
           
          
      
            {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? 
            <>
            <Link to="/analytics" className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/analytics"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  inner List started
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={dashboardIcon} alt="search" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        Analytics
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                inner List ended
              </ListItem>
            </Link>
            </>:""}
             */}
             <>
              <div>
            <Link className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/feeCycles" ? "list-top" : "list-top-2"
                }`}
              >
                <Button color="primary"  size="small" fullWidth   onClick={toggleDropdown}  style={{ backgroundColor: isOpen ? '#ffedee' : ''  ,textTransform: 'none'}}>
                  {/* inner List started */}
                  
                  <List className=" List-main-inner" >
                    <ListItem>
                       <img style={{width:'20px'}} src={statusIcon} alt="fee cycle"/>
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1" gutterBottom>
                          
                        <div className="dropdown-button" style={{display:'flex',justifyContent: 'space-between',width:"11.5vw"}} >
                          <span>Status</span>
                           <ListItem>
                      {isOpen? (  <ExpandMoreIcon  style={{ width: '25px', height: '30px', color:'#667085' }}/> ) : (
                        <KeyboardArrowRightIcon style={{ width: '25px', height: '30px', color:'#667085'  }} />)}
                    </ListItem>
                      </div>
                      </Typography>
                    </ListItem>
                    
                  </List>
                </Button>
                
                {/* inner List ended */}
              </ListItem>

            </Link>
        <div className="sidebar-data-open">
      {isOpen && (
  <div style={{width:'15vw',
  display: 'flex',
  flexDirection: 'column',backgroundColor:'#FFFCFC',borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",borderBottom:'2px solid #D32028',
  borderTop:'2px solid #D32028'}}>
      
      <a  onClick={()=>handleNavigate("/status/Submission")}  className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',marginTop:'10px',cursor:'pointer'}} >
        <div   style={{display: 'flex',alignItems:"center", flexDirection: 'row',justifyContent: 'space-between', lineHeight:'30px'}}>
          <p id="InSubmission"  style={{display: 'inline',whiteSpace:'nowrap',color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Submission" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Submission" ? "600" : ""}}>In Submission</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p  id="InSubmissionCount" className={location.pathname.split('/')[2]==="Submission"?"count":"count-select"} >{dropdownItems.find((item) => item.status === 'In Submission')?.count || 0}</p> : "" }  
        </div>
      </a>
      <a onClick={()=>handleNavigate("/status/Review")}  className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="InReview" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Review" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Review" ? "600" : ""}}>In Review</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}  
        </div>
      </a>
      <a  onClick={()=>handleNavigate("/status/Process")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="InProcess" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Process" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Process" ? "600" : ""}}>In Process</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p id="InProcessCount"  className={location.pathname.split('/')[2]==="Process"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Process')?.count || 0}</p>:''}  
        </div>
      </a>
      <a onClick={()=>handleNavigate("/status/Sanction")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="InSanction" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Sanction" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Sanction" ? "600" : ""}}>In Sanction</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p  id="InSanctionCount" className={location.pathname.split('/')[2]==="Sanction"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Sanction')?.count || 0}</p>:''}  
        </div>
      </a>
      {decode.role === "cra"?"":(
      <a  onClick={()=>handleNavigate("/status/Disbursement")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
      <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
        <p id="InDisbursement" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Disbursement" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Disbursement" ? "600" : ""}}>In Disbursement</p>
      {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p  id="InDisbursementCount"  className={location.pathname.split('/')[2]==="Disbursement"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'Ready to Disburse')?.count || 0}</p>:''}  
      </div>
      </a>
      )}
     {decode.role === "cra"?"":(
      <>
      {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ?   <a  onClick={()=>handleNavigate("/status/Pending")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Pending" style={{display: 'inline',whiteSpace:'nowrap',color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Pending" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Pending" ? "600" : ""}}>Pending Approvals</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p id="PendingCount"   className={location.pathname.split('/')[2]==="Pending"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'Pending Disbursement')?.count || 0}</p>:''}  
        </div>
      </a>:"" }
      </>
     )}
      
      {decode.role === "cra"?"":(
      <a onClick={()=>handleNavigate("/status/Disbursed")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
      <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
        <p  id="InDisbursed" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Disbursed" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Disbursed" ? "600" : ""}}>Disbursed</p>
      {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p id="InDisbursedCount"  className={location.pathname.split('/')[2]==="Disbursed"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'Disbursed')?.count || 0}</p>:''}  
      </div>
      </a>
      )}
     
      <a  onClick={()=>handleNavigate("/status/Dropped")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p  id="Dropped"  style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Dropped" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Dropped" ? "600" : ""}}>Dropped</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p id="DroppedCount"  className={location.pathname.split('/')[2]==="Dropped"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'Dropped')?.count || 0}</p>:''}  
        </div>
      </a>
      <a  onClick={()=>{handleNavigate("/status/Rejected") }} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Rejected" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Rejected" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Rejected" ? "600" : ""}}>Rejected</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p id="RejectedCount" className={location.pathname.split('/')[2]==="Rejected"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'Rejected')?.count || 0}</p>:''}  
        </div>
      </a>
      <a  onClick={()=>handleNavigate("/status/Hold")} className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',cursor:'pointer'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="onHold" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[2] === "Hold" ? "underline" : "",fontWeight:location.pathname.split('/')[2] === "Hold" ? "600" : ""}}>On Hold</p>
        {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <p  id="onHoldCount" className={location.pathname.split('/')[2]==="Hold"?"count":"count-select"} >{dropdownItems.find((item) => item.status === 'On Hold')?.count || 0}</p>:''}  
        </div>
      </a>
      
      
    
  </div>
      )}
      
      
     
    </div>
      
            </div>
             </>

          {decode.role === "cra" || decode.role === "crm" || decode.role === "admin"||decode.role === "finance"?"":(
            <>
             {decode.role !=="institute_user" && decode.role!=="institute_admin" ?
          <>
            <div>
              <div>
              <Link  className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/applications1"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small"  onClick={toggleDropdown1} style={{  backgroundColor: isOpen1 ? '#ffedee' : '',  textTransform: 'none'   }} >
                  {/* inner List started */}
                 
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={homeIcon} alt="img"/>
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                           
                        <div className="dropdown-button" style={{display:'flex',justifyContent: 'space-between',width:"11.5vw"}} >
                          <span>Applications</span>
                          <ListItem>
                      {isOpen1 ? (  <ExpandMoreIcon  style={{ width: '25px', height: '30px',  }}/> ) : (
                        <KeyboardArrowRightIcon style={{ width: '25px', height: '30px', color:'#667085' }} />)}
                    </ListItem>
                      </div>
                      </Typography>
                    </ListItem>
                   
                  </List>
                 
                </Button>
                {/* inner List ended */}
              </ListItem>
            </Link>
        <div className="sidebar-data-open">
      {isOpen1 && (
  <div style={{width:'15vw',
  display: 'flex',
  flexDirection: 'column',backgroundColor:'#FFFCFC',borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",borderBottom:'2px solid #D32028',
  borderTop:'2px solid #D32028'
  
 }}>
      
     
     
      <a href="/applications"  className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',marginTop:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="InProcess" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem"}}>In Process</p>
        </div>
      </a>
      <a href="/status/Disbursed" className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p  id="InDisbursed" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem"}}>Disbursed</p>
        </div>
      </a>
      <a href="/status/Dropped" className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p  id="Dropped"  style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem"}}>Dropped</p>
        </div>
      </a>
      <a href="/status/Rejected"  className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Rejected" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem"}}>Rejected</p>
        </div>
      </a>
      <a href="/status/Hold"  className="hover-effect" style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="onHold" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem"}}>On Hold</p>
        </div>
      </a>
      
  
  </div>
      )}
      
      
     
    </div>
      
            </div>
          </div>
          </> :""}
            </>
          )}
         

          {decode.role === "cra"?"":(
            <>
             {decode.role !=="institute_user" && decode.role!=="institute_admin" ? 
          <>
          <Link onClick={()=>toggleDropdownHome()} className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary"    fullWidth size="small"  style={{backgroundColor:isOpenHome?'#ffedee':""}}>
             
                  {/* inner List started */}
                  <List className=" List-main-inner " >
                    <ListItem>
                      <AppRegistrationIcon sx={{color:'#667085', width:'20px'}}/>
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography variant="body1" gutterBottom>
                      <div className="dropdown-button" style={{display:'flex',justifyContent: 'space-between',width:"11.5vw"}} >
                        <span >Reports </span>
                        <ListItem>
                      {isOpenHome ? (  <ExpandMoreIcon  style={{ width: '25px', height: '30px' }}/> ) : (
                        <KeyboardArrowRightIcon style={{ width: '25px', height: '30px', color:'#667085' }} />)}
                    </ListItem>
                        </div>
                      </Typography>
                    </ListItem>  
                    
                  </List>
                 
                </Button>
                {/* inner List ended */}
              </ListItem>
            </Link> 
            <div className="sidebar-data-open">
      {isOpenHome && (
  <div style={{width:'15vw',
  display: 'flex',
  flexDirection: 'column',backgroundColor:'#FFFCFC',borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",borderBottom:'2px solid #D32028',
  borderTop:'2px solid #D32028'
  
 }}>
      
      {/* <a href="/dashboard"   className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px',marginTop:'10px'}} > */}
        {/* <div   style={{display: 'flex',alignItems:"center", flexDirection: 'row',justifyContent: 'space-between', lineHeight:'30px'}}>
          <p id="InSubmission" style={{display: 'inline',whiteSpace:'nowrap',color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "dashboard" ? "underline" : ""}}>Apply Now</p> */}
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InSubmissionCount" className={location.pathname.split('/')[2]==="Submission"?"count":"count-select"} >{dropdownItems.find((item) => item.status === 'In Submission')?.count || 0}</p> : "" }   */}
        {/* </div></a>  */}
      
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/analytics"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Analytics" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "analytics" ? "underline" : ""}}>Analytics</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/DemandReport"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="DemandReport" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "DemandReport" ? "underline" : ""}}>Demand Reports</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/Monthlydisbursements"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Monthlydisbursements" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "Monthlydisbursements" ? "underline" : ""}}>Disbursements</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/Portfoliocuts"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Portfoliocuts" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "Portfoliocuts" ? "underline" : ""}}>Portfolio Cuts</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/MonthlyPAR"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="MonthlyPAR" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "MonthlyPAR" ? "underline" : ""}}>Par Reports</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/CollectionPerformance"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="CollectionPerformance" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "CollectionPerformance" ? "underline" : ""}}>CollectionPerformance</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}

      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/Closedloans"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Closedloans" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "Closedloans" ? "underline" : ""}}>Closed loans</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/Legalreport"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Legalreport" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "Legalreport" ? "underline" : ""}}>Legal report</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}

      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/Mandatepausedreport"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Mandatepausedreport" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "Mandatepausedreport" ? "underline" : ""}}>Mandetepaused report</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/PoolTagedreport"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="PoolTagedreport" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "PoolTagedreport" ? "underline" : ""}}>PoolTag report</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}

      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/Demographical"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="Demographical" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "Demographical" ? "underline" : ""}}>Institute Dashboard</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}


      { decode.role!=="institute_user"&&decode?.role!=="institute_admin" ? <a href="/reports"    className="hover-effect"  style={{textDecoration:'none',paddingLeft:'20px',paddingRight:'10px'}}>
        <div style={{width:"100%",display: 'flex', flexDirection: 'row', justifyContent: 'space-between',lineHeight:'30px'}}>
          <p id="reports" style={{display: 'inline',whiteSpace:"nowrap",color:"black",fontFamily:'Inter-Medium',fontSize:"1rem",textDecoration: location.pathname.split('/')[1] === "reports" ? "underline" : ""}}>Master Data</p>
        {/* {decode.role!=="institute_user"&&decode.role!=="institute_admin" ? <p  id="InReviewCount" className={location.pathname.split('/')[2]==="Review"?"count":"count-select"}>{dropdownItems.find((item) => item.status === 'In Review')?.count || 0}</p>:''}   */}
        </div>
      </a>:null}
      

      { decode.role !== "institute_user" && decode?.role !== "institute_admin" ? 
     <a href="https://lookerstudio.google.com/reporting/ceac147a-f414-49e5-b951-be7754797f93"    target="_blank" 
     rel="noopener noreferrer"
     className="hover-effect" 
     style={{ textDecoration: 'none', paddingLeft: '20px', paddingRight: '10px' }}>
    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', lineHeight: '30px' }}>
      <p id="Apiconsumption" 
         style={{ display: 'inline', whiteSpace: "nowrap", color: "black", fontFamily: 'Inter-Medium', fontSize: "1rem", textDecoration: location.pathname.split('/')[1] === "Apiconsumption" ? "underline" : "" }}>
        Apiconsumption
      </p>
     
    </div>
  </a> : null
}
      
  
  </div>
      )}
      
      
     
    </div></> :""}
            </>
          )}
         
          {decode.role === "cra"?"":(
            <>
             {decode.role !=="institute_user" && decode.role!=="institute_admin" ? 
          <>
          <Link onClick={()=>toggleDropdownLMS()} className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary"    fullWidth size="small"  style={{backgroundColor:isOpenLMS?'#ffedee':""}}>
             
                  {/* inner List started */}
                  <List className=" List-main-inner " >
                    <ListItem>
                      <Diversity2Icon  sx={{color:'#667085', width:'20px'}} />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography variant="body1" gutterBottom>
                      <div className="dropdown-button" style={{display:'flex',justifyContent: 'space-between',width:"11.5vw"}} >
                        <span >LMS</span>
                        <ListItem>
                      {isOpenLMS ? (  <ExpandMoreIcon  style={{ width: '25px', height: '30px',}}/> ) : (
                        <KeyboardArrowRightIcon style={{ width: '25px', height: '30px', color:'#667085' }} />)}
                    </ListItem>
                        </div>
                      </Typography>
                    </ListItem>  
                    
                  </List>
                 
                </Button>
                {/* inner List ended */}
              </ListItem>
            </Link>
            <div className="sidebar-data-open">
      {isOpenLMS && (
  <div style={{width:'15vw',
  display: 'flex',
  flexDirection: 'column',backgroundColor:'#FFFCFC',borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",borderBottom:'2px solid #D32028',
  borderTop:'2px solid #D32028'
  
 }}>



{decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <>
            <a href = "/Loans" className="linkdco" >
              <ListItem
                className={`${
                  location.pathname === "/Loans"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  {/* inner List started */}
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={loans} alt="loans" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        Loans
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                {/* inner List ended */}
              </ListItem>
            </a  >

            <a href ="/Repayments"  className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/Repayments"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  {/* inner List started */}
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={repayment} alt="loans" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        Repayments
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                {/* inner List ended */}
              </ListItem>
            </a >
            <a href ="/Emis"  className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/Emis"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  {/* inner List started */}
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={emis} alt="loans" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        EMI's
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                {/* inner List ended */}
              </ListItem>
            </a >
            <a href ="/Overdue"  className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/Overdue"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  {/* inner List started */}
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={overdue} alt="loans" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        Overdue
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                {/* inner List ended */}
              </ListItem>
            </a >
           
            </>:''}


 </div>
            
)}

</div> </> :""}
            </>
          )}
    
          {decode.role !=="institute_user" && decode.role!=="institute_admin" ? 
                    <>
                    <Link onClick={()=>toggleDropdownAdmin()} className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary"    fullWidth size="small"  style={{backgroundColor:isOpenAdmin?'#ffedee':""}}>
                      
                            {/* inner List started */}
                            <List className=" List-main-inner " >
                              <ListItem>
                                <ManageAccountsIcon sx={{color:'#667085', width:'20px'}}/>
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography variant="body1" gutterBottom>
                                <div className="dropdown-button" style={{display:'flex',justifyContent: 'space-between',width:"11.5vw"}} >
                                  <span >Admin</span>
                                  <ListItem>
                                {isOpenAdmin ? (  <ExpandMoreIcon  style={{ width: '25px', height: '30px' }}/> ) : (
                                  <KeyboardArrowRightIcon style={{ width: '25px', height: '30px', color:'#667085' }} />)}
                              </ListItem>
                                  </div>
                                </Typography>
                              </ListItem>  
                              
                            </List>
                          
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </Link>
                      <div className="sidebar-data-open">
                {isOpenAdmin && (
            <div style={{width:'15vw',
            display: 'flex',
            flexDirection: 'column',backgroundColor:'#FFFCFC',borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",borderBottom:'2px solid #D32028',
            borderTop:'2px solid #D32028'
            
          }}>



          {decode.role!=="institute_user"&&decode.role!=="institute_admin"&&decode?.role!=="institute_admin" ? <>
                    
                      <a href ="/products" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/products"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <img style={{width:'20px'}} src={box} alt="search" />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Products
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a>
                      <a href ="/Institute" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/Institute"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <img style={{width:'20px'}} src={institute} alt="search" />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Institutes
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a >
                      <a href ="/Activebanks" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/Activebanks"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <AccountBalanceIcon/>
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Activebanks
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a> 


                      <a href ="/MCIsearch" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/MCIsearch"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <MedicalInformationIcon  />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  MCIsearch
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a> 


                      <a href ="/PoolTagging/" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/PoolTagging/"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <TagIcon  />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Pooltag
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a> 
                      {/* <a href ="/rules" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/rules"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            <List className=" List-main-inner ">
                              <ListItem>
                              <img style={{width:'23px'}} src={rules} alt="search" />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Rule Engine
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                        </ListItem>
                      </a>  */}
                      </>:''}
                      { decode?.role==="institute_admin"||decode?.role==="admin" ? <a href ="/roles" className="linkdco">
                        <ListItem
                          className={`${
                            location.pathname === "/roles"
                              ? "list-top"
                              : "list-top-2"
                          }`}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <img style={{width:'35px',marginLeft:'-12px'}} src={roles} alt="search" />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Roles
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a>:''}
                      { decode?.role==="institute_admin"||decode?.role==="admin" ? <a href={`https://rules.feemonk.com/${user}`} className="linkdco" target="_blank">
                        <ListItem
                          className={"list-top-2"}
                        >
                          <Button color="primary" fullWidth size="small">
                            {/* inner List started */}
                            <List className=" List-main-inner ">
                              <ListItem>
                                <img style={{width:'35px',marginLeft:'-12px'}} src={rules} alt="search" />
                              </ListItem>
                              <ListItem className="user-setting">
                                <Typography
                                  variant="body1"
                                  fontFamily="Inter-Medium"
                                  gutterBottom
                                >
                                  Rules Engine
                                </Typography>
                              </ListItem>
                            </List>
                          </Button>
                          {/* inner List ended */}
                        </ListItem>
                      </a>:''}


          </div>
                      
          )}

          </div> </> :""}    
           
         
                {(decode.role === "institute_user" || decode.role === "institute_admin") ? 
                  <>
                    <a href="/reports" className="linkdco">
                      <ListItem
                        className={`${
                          location.pathname === "/reports"
                            ? "list-top"
                            : "list-top-2"
                        }`}
                      >
                        <Button color="primary" fullWidth size="small">
                          {/* inner List started */}
                          <List className="List-main-inner">
                            <ListItem>
                              <img style={{ width: '25px' }} src={masterdata} alt="search" />
                            </ListItem>
                            <ListItem className="user-setting">
                              <Typography
                                variant="body1"
                                fontFamily="Inter-Medium"
                                gutterBottom
                              >
                                Reports
                              </Typography>
                            </ListItem>
                          </List>
                        </Button>
                        {/* inner List ended */}
                      </ListItem>
                    </a> 
                  </> : ''
                }
                {decode?.instituteId === "9d5eca4b-54f7-42a7-8794-1413e00b462e"?(
                  <a href ="/Emi"  className="linkdco">
              <ListItem
                className={`${
                  location.pathname === "/Emi"
                    ? "list-top"
                    : "list-top-2"
                }`}
              >
                <Button color="primary" fullWidth size="small">
                  {/* inner List started */}
                  <List className=" List-main-inner ">
                    <ListItem>
                      <img style={{width:'20px'}} src={emis} alt="loans" />
                    </ListItem>
                    <ListItem className="user-setting">
                      <Typography
                        variant="body1"
                        fontFamily="Inter-Medium"
                        gutterBottom
                      >
                        Emis
                      </Typography>
                    </ListItem>
                  </List>
                </Button>
                {/* inner List ended */}
              </ListItem>
            </a >
                ):null}
            
           
         
           </List>
          {/* {profileDisplay!="100%"?<Card className="nav-card">
            <Box component="img" alt="" src={Ring} />
            <Typography
              variant="body2"
              color="warning.main"
              fontFamily="Inter-Medium"
              className="ring-text"
              gutterBottom
            >
              Profile Status
            </Typography>
            <Typography
              variant="body2"
              color="primary.dark"
              fontFamily="Inter-Medium"
              className="ring-text"
              gutterBottom
            >
              You have finished {profileDisplay} of your profile. Please{" "}
              <span className="click-here" onClick={() => navigate('/myprofile')}>click here </span> to complete
            </Typography>
          </Card>:null} */}
          
        </div>
      </Homestyle>
      </>
    ):<ArrowForwardIosIcon onClick={()=>{handlerFunction()}} style={{cursor:'pointer'}}/>}
     

    </>
  );
}